/** @format */

.countdown {
  animation: countdown 1s ease-in-out infinite;
}

@keyframes countdown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.paddingTop {
  padding-top: 150px;
}

.max-width-container-footer {
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 300px;
  padding-right: 300px;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .paddingTop {
    padding-top: 170px;
  }

  .max-width-container-footer {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 50px;
  }
}
