/** @format */

.navbarEdit {
  background-color: #1a1a1e;
}

.ajustCenter {
  max-width: 950px;
  padding-top: 100px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 28px;
  color: #ffffff;
}

h2 {
  color: #c0c0c0;
}

h3 {
  color: #c0c0c0;
}

h4 {
  color: #a9a9a9;
}

h5 {
  color: #a9a9a9;
}

h6 {
  color: #696969;
}

p {
  color: #808080;
}

.h1form {
  color: #ffffff;
}

.label {
  color: #d3d3d3;
  font-size: 30px;
}

.containervar {
  margin-top: 20px;
}

form {
  border-radius: 20px;
}

.divfooter {
  padding-right: 10px;
  padding-left: 10px;
}

footer {
  background: #1a1a1e;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  margin: 0 auto !important;
}

footer ul {
  display: table;
}

.textColorFooter {
  color: #808080;
}

.divalerttop {
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .divalerttop {
    margin-top: 35%;
  }
}

@media only screen and (min-width: 769px) {
  .divalerttop {
    margin-top: 15%;
  }
}

.alertprincipal {
  padding-right: 20px;
  padding-left: 20px;
}

.alertfooter {
  margin-right: 20px;
  margin-left: 20px;
}

.divform {
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.formpricipal {
  margin-top: 20px;
  margin-bottom: 25px;
  padding-top: 20px;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 20px;
}

@keyframes borderAnimationBlue {
  0% {
    border-image-source: linear-gradient(
      0deg,
      rgb(38, 227, 196),
      rgb(14, 1, 44),
      #5a6c80,
      rgb(38, 227, 196)
    );
  }
  25% {
    border-image-source: linear-gradient(
      90deg,
      rgb(38, 227, 196),
      rgb(14, 1, 44),
      #5a6c80,
      rgb(38, 227, 196)
    );
  }
  50% {
    border-image-source: linear-gradient(
      180deg,
      rgb(38, 227, 196),
      rgb(14, 1, 44),
      #5a6c80,
      rgb(38, 227, 196)
    );
  }
  75% {
    border-image-source: linear-gradient(
      270deg,
      rgb(38, 227, 196),
      rgb(14, 1, 44),
      #5a6c80,
      rgb(38, 227, 196)
    );
  }
  100% {
    border-image-source: linear-gradient(
      360deg,
      rgb(38, 227, 196),
      rgb(14, 1, 44),
      #5a6c80,
      rgb(38, 227, 196)
    );
  }
}

.bottomStyle {
  justify-content: center;
  color: #ffffff;
  align-items: center;
  background: linear-gradient(to right, #23232e, #1a1a1e);
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  border: 2px solid transparent;
  border-image: linear-gradient(to right);
  border-image-slice: 1;
  padding: 5px;
  margin-top: 10px;
  transition: background 0.3s ease-in-out, border-image-source 0.3s ease-in-out;
}

.bottomStyle:hover {
  color: #000000;
  background: linear-gradient(to right, #23232e, #1a1a1e);
  border-image-source: linear-gradient(
    45deg,
    rgb(38, 227, 196),
    rgb(14, 1, 44),
    #5a6c80,
    rgb(38, 227, 196)
  );
  animation: borderAnimation 5s linear infinite;
}

#msg {
  margin-top: 20px;
}

.divform-informations {
  padding-right: 10px;
  padding-left: 10px;
}

.aboutus {
  padding-top: 100px;
  padding-right: 20px;
  padding-left: 20px;
}

.sociaismidian {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-items: center;
  align-items: center;
}

.btnfooter {
  width: 100%;
  max-width: 300px;
  text-align: center;
  background: linear-gradient(to right, #1a1a1e, #23232e);
  color: #d3d3d3;
  border-radius: 10px;
  text-decoration: none;
  display: inline-block;
  border: 2px solid transparent;
  border-image: linear-gradient(to right);
  border-image-slice: 1;
  padding: 5px;
  margin-top: 10px;
  transition: background 0.3s ease-in-out, border-image-source 0.3s ease-in-out;
}

.btnfooter:hover {
  color: #000000;
  background: linear-gradient(to right, #23232e, #1a1a1e);
  border-image-source: linear-gradient(
    45deg,
    rgb(38, 227, 196),
    rgb(14, 1, 44),
    #5a6c80,
    rgb(38, 227, 196)
  );
  animation: borderAnimation 5s linear infinite;
}

@media (max-width: 768px) {
  .sociaismidian {
    grid-template-columns: 1fr;
  }

  .btnfooter {
    width: 90%;
  }
}

.link-btn {
  color: inherit;
  text-decoration: none;
}

.link-btn:hover {
  color: black;
}

.mycarousel {
  background: black;
  margin-bottom: 20px;
}

.carouselimg {
  content: "";
  opacity: 0.8;
  max-height: 320px;
  min-height: 300px;
}

.slidetext {
  max-width: 100%;
  max-height: 100%;
  color: white;
}

.item-image {
  max-width: 420px;
  min-height: 200px;
  min-width: 400px;
  padding: 14px;
}

.item-image img {
  width: 100%;
  height: 90%;
  border-radius: 20px;
  pointer-events: none;
}

.recomendations {
  font-size: clamp(1em, 1em + 1vw, 1.5em);
}

.tips {
  padding-left: 10px;
  padding-right: 10px;
}

.imgtips img {
  width: 100%;
  height: 100%;
  max-width: 900px;
  max-height: 420px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.containerqrcod {
  max-width: 500px;
  max-height: 500px;
}

.qrcodeimage {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 180px;
}

.yotubetips {
  width: 100%;
  height: 420px;
  max-width: 900px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.adsstyle {
  max-width: 25%;
  max-height: 25%;
  margin-left: 20px;
  justify-content: flex;
  align-items: flex;
}

.videoInformation {
  width: 100%;
  height: 520px;
  max-width: 900px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tablecenter {
  justify-content: center;
  align-items: center;
}

.table {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
  user-select: none;
}

td {
  border: 1px solid #ccc;
  padding: 5px;
}

input[type="text"] {
  border: none;
  box-shadow: inset 0 -1px 0 #ccc;
  font-size: inherit;
  padding: 2px;
  width: 100%;
}

input[type="text"]:focus {
  box-shadow: inset 0 -2px 0 #2196f3;
  outline: none;
}

.btncopy {
  background-color: #000000;
}

.textrecomendations {
  font-size: 40px;
}

.btncopy {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .btncopy {
    font-size: 8px;
  }

  .marginTopTips {
    margin-top: 35%;
  }

  .textrecomendations {
    font-size: 25px;
  }

  .marginTopRecomendations {
    margin-top: 30%;
  }

  .tablecenter table {
    width: auto;
    border-collapse: collapse;
  }

  .tablecenter td,
  .tablecenter th {
    word-wrap: break-word;
    border: 1px solid #ddd;
    padding: 8px;
  }
  .tablecenter th {
    background-color: #f2f2f2;
  }
}

@media only screen and (min-width: 768px) {
  .marginTopTips {
    margin-top: 10%;
  }

  .marginTopRecomendations {
    margin-top: 7%;
  }
}

.textColorTableRecomendations {
  color: red;
}

input {
  color: #ffffff;
  background-color: #000000;
}

.logoimg {
  buttom: 0;
}

@media only screen and (min-width: 768px) {
  .discordLogo {
    buttom: 0;
    margin-top: 10px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 819px) {
  #logoimg {
    margin-right: 150px;
    max-width: 250px;
  }

  .discordLogo {
    buttom: 0;
    margin-top: 10px;
    padding-right: 20px;
    margin-left: -25%;
  }

  #discord {
    max-width: 60px;
    max-height: 30px;
  }
}

/* Bottom affiliate */

.d-flex {
  display: flex;
  align-items: center;
}

.select-with-button {
  flex-grow: 1;
}

.btn {
  margin-left: 10px;
  font-size: 14px;
  background-color: #007bff;
  border: 1px solid #007bff;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  white-space: nowrap;
}

.btn:hover {
  background-color: #0056b3;
}

/* select style effect */
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes borderAnimation {
  0% {
    border-image-source: linear-gradient(
      0deg,
      #00ced1,
      #ff1493,
      #8a2be2,
      #00ced1
    );
  }
  25% {
    border-image-source: linear-gradient(
      90deg,
      #00ced1,
      #ff1493,
      #8a2be2,
      #00ced1
    );
  }
  50% {
    border-image-source: linear-gradient(
      180deg,
      #00ced1,
      #ff1493,
      #8a2be2,
      #00ced1
    );
  }
  75% {
    border-image-source: linear-gradient(
      270deg,
      #00ced1,
      #ff1493,
      #8a2be2,
      #00ced1
    );
  }
  100% {
    border-image-source: linear-gradient(
      360deg,
      #00ced1,
      #ff1493,
      #8a2be2,
      #00ced1
    );
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Buttom affiliate style */
.btn-affiliate {
  background: linear-gradient(to right, #1a1a1e, #23232e);
  color: #00ffff;
  margin-left: 7px;
  padding: 5px 10px;
  border-radius: 10px;
  text-decoration: none;
  display: inline-block;
  border: 2px solid transparent;
  border-image-source: linear-gradient(
    45deg,
    #00ced1,
    rgb(13, 8, 33),
    rgb(38, 227, 196),
    #00ced1
  );
  border-image-slice: 1;
  transition: background 0.3s ease-in-out, border-image-source 0.3s ease-in-out;
  animation: borderAnimation 5s linear infinite;
}

/* Efeito de hover */
.btn-affiliate:hover {
  color: #00ffff;
  background: linear-gradient(to right, #23232e, #1a1a1e);
  border-image-source: linear-gradient(
    45deg,
    rgb(38, 227, 196),
    rgb(14, 1, 44),
    #5a6c80,
    rgb(38, 227, 196)
  );
}

@media only screen and (max-width: 768px) {
  .btn-affiliate {
    margin-left: 5px;
    font-size: 15px;
  }
}
