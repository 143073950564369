.container {
  width: 250px;
  height: 375px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  position: relative;
}

@media (max-width: 900px) {
  .container {
    width: 100%;
    height: 90%;
  }
}

.hidden {
  display: none;
}

.padding-top-h3{
  padding-top:100px;
}

@media (max-width: 900px) {
  .padding-top-h3 {
    padding-top: 20px;
  }
}

.animated-border {
  width: 135%;
  height: 110%;
  background: #00ffff;
  position: absolute;
  animation: rotate 10s infinite ease-in-out;
}

@media (max-width: 900px) {
  .animated-border {
    width: 100%;
    height: 120%;
  }
}

.content {
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #00ffff;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  max-height: 100%;
}


.corner {
  width: 100%;
  height: 97%;
  background: #000;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate {
  53% {
    transform: rotate(360deg);
  }
}
